import axios from 'axios';

// 查询总题数
export const countNum = apiParams => {
  return axios({
    method: 'GET',
    url: `topic/count?subject=${apiParams}`
  })
}

// 查询训练题目分页
export const countList = apiParams => {
  return axios({
    method: 'GET',
    url: 'topic/getAllTopic',
    params: apiParams
  })
}

// 查询科四题目
export const countFour = apiParams => {
  return axios({
    method: 'get',
    url: 'topic/getSubjectFour',
    params: apiParams
  })
}

// 查询科一题目
export const countOne = apiParams => {
  return axios({
    method: 'GET',
    url: 'topic/getSubjectOne',
    params: apiParams
  })
}

//  查询缓存题目
export const getcacheTopic = apiParams => {
  return axios({
    method: 'GET',
    url: '/topic/get/cache',
    params: apiParams
  })
}

//  判断是否为超级VIP
export const isSupperVip = apiParams => {
  return axios({
    method: 'GET',
    url: '/user/isSuperVip'
  })
}

// 取消缓存
export const getCache = apiParams => {
  return axios({
    method: 'GET',
    url: 'topic/get/cache'
  })
}