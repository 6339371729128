import axios from 'axios';

// 登录
export const login = apiParams => {
  return axios({
    method: 'post',
    url: '',
    params: apiParams
  })
}
// 获取用户信息
export const getUser = apiParams => {
  return axios({
    method: 'get',
    url: '/user/userinfo',
    params: apiParams
  })
}

// 消息管理
export const noticeList = apiParams => {
  return axios({
    method: 'get',
    url: '/notice/get',
    params: apiParams
  })
}

// 查看消息数量
export const noticeNum = () => {
  return axios({
    method: 'get',
    url: 'notice/num'
  })
}

// 我的推广
export const myPromotion = apiParams => {
  return axios({
    method: 'GET',
    url: 'user/myShare',
    params: apiParams
  })
}

// 获取我的海报的图片
export const getPlaybill = apiParams => {
  return axios({
    method: 'GET',
    url: 'user/QRCode',
    params: apiParams
  })
}

// 微信支付
export const wxPay = apiParams => {
  return axios({
    method: 'post',
    url: `order/pay?projectId=${apiParams.projectId}`
  })
}

// 订单查询
export const orderList = apiParams => {
  return axios({
    method: 'get',
    url: '/order/myOrder',
    params: apiParams
  })
}

// 登录
export const isVip = apiParams => {
  return axios({
    method: 'get',
    url: 'user/login',
    params: apiParams
  })
}

// 判断是否为会员
export const vip = () => {
  return axios({
    method: 'get',
    url: 'user/isVip'
  })
}

// 查询支付方案
export const payagent = () => {
  return axios({
    method: 'get',
    url: '/payProject/get'
  })
}

// 获取海报和客服热线
export const getDetail = apiParams => {
  return axios({
    method: 'GET',
    url: 'admin/getConfig',
    params: apiParams
  })
}

// 获取佣金
export const getmoey = apiParams => {
  return axios({
    method: 'GET',
    url: 'user/getMoney',
    params: apiParams
  })
}

// 缓存题库
export const cachetopIc = apiParams => {
  return axios({
    method: 'post',
    url: '/topic/cache',
    data: apiParams
  })
}

// 专享练习
export const getspeacilList = apiParams => {
  return axios({
    method: 'get',
    url: `/special/types?subject=${apiParams}`,
  })
}

// 专享练习题目
export const getcoumsList = apiParams => {
  return axios({
    methods: 'get',
    url: `/special/list?specialType=${apiParams.chooseTitle}&subject=${apiParams.car}`
  })
}
