<template>
  <div class="testscore">
    <van-nav-bar
      title="考试"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- <p @click="gotest"></p> -->
    <div class="testscoreMain">
      <div class="icon">
        <img src="../assets/image/jclogo.jpg" alt="图标">
      </div>
      <div class="maincenter">
        <van-row>
          <van-col span="12">
            <p class="title">全国通用</p>
          </van-col>
          <van-col span="12">
            <p class="text">小车C1</p>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <p class="title">考试题数</p>
          </van-col>
          <van-col span="12">
            <p class="text">{{car == 1 ? '100题' : '50题'}}</p>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <p class="title">考试时间</p>
          </van-col>
          <van-col span="12">
            <p class="text">45分钟</p>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <p class="title">考试标准</p>
          </van-col>
          <van-col span="12">
            <p class="text">满分100分 及格90分</p>
          </van-col>
        </van-row>
      </div>
      <div class="bottomtest">
        <div class="btn" @click="gotest">
          <p class="text">རྒྱུགས་ལེན་མགོ་བརྩོམས །</p>
          <p class="text">开始考试</p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { vip } from '@/api'
import { countOne, countFour } from "@/api/count";
import { Dialog } from 'vant'
export default {
  name:'',
  components: {

  },
  props: {

  },
  data() {
    return {
      car: 1
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.car = this.$route.query.id;
    
  },
  mounted() {

  },
  methods: {
    // 返回
    onClickLeft() {
      this.$router.back(-1)
    },
    // 获取科一考试题目
    getcount() {
      countOne().then((res) => {
        // this.countList = res.data;
        // this.countForm = this.countList[0];
        if (res.httpStatus === 200) {
          (res.data).forEach(item => {
            if (!item.audios || (item.audios).length === 0) {
              item.audios = []
            } else if (item.audios[0].indexOf('uid') !== -1) {
              item.audios[0] =  JSON.parse(item.audios[0])
            }
            if (!item.videos || item.videos.length == 0) {
              item.videos = []
            } else if (item.videos[0].indexOf('uid') !== -1) {
              item.videos[0] =  JSON.parse(item.videos[0])
            }
            if (!item.imgList || item.imgList.length == 0) {
              this.imgList = []
            } else if (item.imgList[0].indexOf('uid') !== -1) {
              item.imgList[0] =  JSON.parse(item.imgList[0])
            }
            
            item.result = []
            item.cutindex = ''
            item.trueIndex = ''
            item.isSelect = false
          });
          if (res.data.length === 0) {
            Dialog.alert({
              title: '提示',
              message: '暂无题目,正在升级敬请期待~'
            })
          return
          } else {
            localStorage.setItem('tixing', JSON.stringify(res.data))
            this.$router.push({
              path: '/examination',
              query: {
                id: this.car
              }
            })
          }
        } else {
          this.$toast({
            message: `${res.message}`,
            duration: 2000
          })
        }
      });
    },
    // 获取科四考试题目
    getfour() {
      countFour().then((res) => {
        if (res.httpStatus === 200) {
          (res.data).forEach(item => {
            if (!item.audios || (item.audios).length === 0) {
              item.audios = []
            } else if (item.audios[0].indexOf('uid') !== -1) {
              item.audios[0] =  JSON.parse(item.audios[0])
            }
            if (!item.videos || item.videos.length == 0) {
              item.videos = []
            } else if (item.videos[0].indexOf('uid') !== -1) {
              item.videos[0] =  JSON.parse(item.videos[0])
            }
            if (!item.imgList || item.imgList.length == 0) {
              this.imgList = []
            } else if (item.imgList[0].indexOf('uid') !== -1) {
              item.imgList[0] =  JSON.parse(item.imgList[0])
            }
            item.result = []
            item.cutindex = ''
            item.trueIndex = ''
            item.isSelect = false
          });
          if (res.data.length === 0) {
            Dialog.alert({
              title: '提示',
              message: '暂无题目,正在升级敬请期待~'
            })
          return
          } else {
            localStorage.setItem('tixing', JSON.stringify(res.data))
            this.$router.push({
              path: '/examination',
              query: {
                id: this.car
              }
            })
          }
          // localStorage.setItem('tixing', JSON.stringify(res.data))
          // this.$router.push({
          //   path: '/examination',
          //   query: {
          //     id: this.car
          //   }
          // })
        } else {
          this.$toast({
            message: `${res.message}`,
            duration: 2000
          })
        }
      });
    },
    // 去考试
    gotest() {
      vip().then(res => {
        localStorage.setItem('vip', res.data)
      })
      if (this.car == 1) {
        this.getcount();
      } else {
        this.getfour();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.testscore{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .testscoreMain{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    .icon{
      width: 64px;
      height: 64px;
      margin-top: 40px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .maincenter{
      width: 375px;
      height: 152px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6E7278;
      line-height: 38px;
      .title{
        color: #6E7278;
        text-align: right;
        padding-right: 10px;
      }
      .text{
        color: #000;
      }
    }
    .bottomtest{
      width: 375px;
      height: 284px;
      background: #FFFFFF;
      box-shadow: 0px -5px 4px 0px rgba(222, 222, 222, 0.25);
      border-radius: 50% 50% 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: linear-gradient(180deg, #5CB9FF 0%, #267CFA 100%);
        box-shadow: 0px 0px 11px 0px #0589EE;
        margin-top: 56px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text{
          text-align: center;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 28px;
        }
      }
    }
  }
  
}
</style>
